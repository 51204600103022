.welcome-content {
    width:100vw;
    height:100vh;
}

.welcome-video {
    position: absolute;
    z-index:-1
    
}

.welcome-section {
    width:100vw;
    height:100vh;
    text-align:center;
}

.welcomeText {
    font-size:32px;
    font-weight:'bold';
    color:white;
}